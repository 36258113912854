/** @format */

import { ENUM_ADMIN_ROLES } from "@prisma/client";
import { Loader } from "@roadflex/react-components";
import { useReadCurrentAdmin } from "@roadflex/react-hooks";
import Redirect from "../components/Redirect";
import SignIn from "../components/sign-in/sign-in";

export default function Index() {
  const { data, loading } = useReadCurrentAdmin();
  if (loading) {
    return <Loader />;
  }
  if (data?.readCurrentAdmin) {
    const roles = data.readCurrentAdmin.roles;
    if (roles.includes(ENUM_ADMIN_ROLES.COLLECTIONS) && roles.length === 1) {
      return <Redirect path="/collections" />;
    }
    if (!roles.includes(ENUM_ADMIN_ROLES.SUPERADMIN)) {
      return <Redirect path="/onboard" />;
    }
    return <Redirect path="/dashboard" />;
  }
  return (
    <>
      <SignIn />
    </>
  );
}
